<template>
<v-col class="mb-5 pb-5">
  <v-row>
    <v-col cols="12" sm="9" md="8" lg="6" class="text-center mx-auto header-section">
      <v-icon class="d-none d-md-flex" style="font-size:6em; margin-bottom:-70px;" color="primary">mdi-sail-boat</v-icon>
      <v-icon class="d-md-none" style="font-size:4em; margin-bottom:-20px;" color="primary">mdi-sail-boat</v-icon>
      <h2 class="mt-3 ml-4 primary--text">Bataille navale</h2>
      <small class="font-papyrus text-left d-block" style="margin-top:40px;">
        Le triangle des bermudes est une zone soumise à une malédiction très ancienne, 
        dont les responsables ne sont autres que deux anciens pirates particulièrement cruels et maléfiques, 
        dénommés Emanuel Wynne et Henry Morgan. 
        Cette malédiction permet à tous les fantômes d'anciens pirates résidant au Royaume des Morts, de redevenir vivants
        aussi longtemps qu'ils naviguent en mer à l'intérieur de ce triangle magique...
      </small>

      <small class="d-block text-right">
        <v-btn rounded small color="primary" text class="ma-2" @click="showText=!showText">
          Lire la suite <v-icon small class="ml-1">mdi-arrow-down-circle</v-icon>
        </v-btn>
      </small>

      <small class="font-papyrus text-left d-block">
        <template v-if="showText">
          <br><br>
          La légende raconte que ces deux brigans se sont rencontrés par hasard au Royaume des Morts, 
          aux alentours des années 1730, quelques années après la mort de Wynne. 
          <br><br>
          Après avoir vidé les derniers tonneaux de rhum d'une taverne mal fâmée, et s'être battus pour s'arracher les dernières goutes, 
          ils s'étaient finalement évanouis tous les deux d'un dernier coup de poing simultané dans la machoir. 
          <br><br>
          Au dela de 5 grammes d'alcool dans le sang, Morgan se découvrait régulièrement des pouvoirs surnaturels totalement incontrôlés. 
          Et ce soir là, par la seule force de sa volonté (et de sa soif de rhum), il avait engendré cette malédiction sans même s'en rendre compte. 
          <br><br>
          "Piratae Bermudarum !!!" s'était-il écrié, en se réveillant en sursaut après quelques minutes d'inconscience.
          Et en un clignement d'oeil, il s'était retrouvé au milieu de l'océan, sur le fantôme de son dernier navir, et au côté de son nouvel ami Wynne héberlué !
          Ils eurent du mal à en croire leurs yeux, lorsqu'ils se rendirent compte qu'ils semblaient à nouveau biens vivants, 
          vogants sur une mer turquoises, sous un soleil de plomb, et appercevant même quelques navires à l'horizon... 
          Une fois la surprise passé, ils s'étaient jetés sur le premier navir de marchandises qui passait à leur portée, 
          et avaient pillé tout le rhum contenu à l'intérieur.
          <br><br>
          Depuis lors, la zone est régulièrement attaquée par des vaisseaux fantômes, et est en proie à des disparitions multiples et inexpliquées.
          <br><br>
          Pour se réincarner, il suffit désormais aux fantômes de se rendre à l'endroit exacte où Morgan s'était réveillé en hurlant, 
          et de répéter la formule "Piratae Bermudarum" trois fois de suite...
          <br><br>
          Cependant, il existe un moyen pour lever cette malédiction : réussir à éliminer tous les pirates fantômes qui se trouvent dans la zone en même temps, 
          avant que de nouveaux pirates ne réapparaissent. 
          Il est dit que les pirates peuvent continuer à apparaitre dans la zone, 
          seulement tant qu'il y a au moins un autre pirate déjà présent sur place. S'il ne reste plus aucun pirate, 
          la malédiction est levée et la zone est libérée... Jusqu'à ce que Morgan lui-même ne renouvelle une fois de plus cette malédiction, si le coeur lui en dit...
          <br><br>
          Car malheureusement, c'est déjà arrivé... En 1962, pendant la "crise des missiles" à Cuba. 
          La zone était devenue tellement dangeureuse que même les plus vils pirates fantômes avaient finit par prendre peur à la vue des navires militaires modernes,
          et avaient déserté la zone pendant quelques semaines, effrayés à l'idée de ne pas être de taille à affronter les canons nucléaires... 
          L'accès maléfique avait alors disparu, et la formule pouvaient être répétée mille fois de suite, elle n'avait plus aucun effet !
          <br><br>
          Mais Morgan, qui ne l'entendait pas de cette oreille, avait débarqué un beau matin à la taverne, avec la ferme intention de rétablir son maléfice.
          Aux environ de 18h, après quelques litres de rhum envoyés entre deux bagares, il avait réussi par on ne sait quel miracle à disparaître à nouveau,
          pour réapparaître comme la première fois en plein milieu océan.
          <br><br>
          Pour tenter de lever une nouvelle fois cette malédiction, nous offrons une récompense à quiconque réussira à détruire quelques-uns de ces navires fantômes.
          <br><br>
          Et une récompense spéciale de 100 PRT sera offerte à celui ou celle qui parviendra à abattre le redoutable Capitaine Henry Morgan au cours d'une bataille...
        </template>
      </small>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" sm="9" md="8" lg="6" class="text-center mx-auto"
           style="border-top:1px dashed #fbc02d;margin-top:50px;margin-bottom:100px;">
      <v-btn to="/game/battle/create" block class="game-menu-btn font-main" elevation="0">
        Nouvelle partie
      </v-btn>
      <v-btn to="/game/battle/load" v-if="$store.state.auth.isLogin" 
             block class="game-menu-btn font-main" elevation="0">
        Charger une partie
      </v-btn>
      <v-btn to="/game/battle/join" v-if="$store.state.auth.isLogin && $store.state.auth.user.planPaid" 
             block class="game-menu-btn font-main" elevation="0">
        Rejoindre une partie
      </v-btn>
      <v-btn to="/game/battle/ranking"
             block class="game-menu-btn font-main" elevation="0">
        Classement général
      </v-btn>
    </v-col>
  </v-row>
</v-col>
</template>

<style>
  @media (max-width: 960px) {
    .header-section h1{
      font-size:60px;
      line-height:50px;
      margin-top:-40px;
    }
  }
</style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'

export default {
  name: 'empty',
  components: {  },
  data: () => ({
    showText: false
    
  }),
  async mounted(){
      this.$store.dispatch('app/incStat', '/game/battle/menu')
  },
  methods: {
    

  },
  computed: {
    
    
  }
}
</script>
